import styled from 'styled-components';

export const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  .toggle-wrapper-md {
    display: flex;
    flex-direction: column;
    align-items: center;
    h5 {
      color: white;
      font-size: 14px;
    }
  }
  .toggle-wrapper-sm {
    display: none;
  }
  .icon-music {
    font-size: 34px;
    color: white;
    cursor: pointer;
  }
  &.in-bet-screen {
    z-index: 2;
  }
`;